<template>
  <v-main app>
    <v-navigation-drawer
      clipped
      app
      fixed
      :permanent="$vuetify.breakpoint.mdAndUp"
      v-model="drawer"
      style="z-index: 1"
    >
      <v-container class="sidebar" fluid>
        <v-list>
          <v-list-item>
            <v-btn class="d-flex justify-start" width="100%" :exact-path="true" outlined color="primary" to="/user/account">
            <v-icon class="mr-4">
              mdi-account
            </v-icon>
            Account
          </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn class="d-flex justify-start" width="100%"  outlined color="primary" to="/user/artworks">
              <v-icon class="mr-4">
                mdi-palette
              </v-icon>
              Artworks
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn class="d-flex justify-start" width="100%"  outlined color="primary" to="/user/preferences">
              <v-icon class="mr-4">
                mdi-checkbox-multiple-marked
              </v-icon>
              Preferences
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn class="d-flex justify-start" width="100%" :exact-path="true" outlined color="primary" to="/user/publicpage">
              <v-icon class="mr-4">
                mdi-web
              </v-icon>
              Public Page
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn class="d-flex justify-start" width="100%" :exact-path="true" outlined color="primary" to="/user/marketingpage">
              <v-icon class="mr-4">
                mdi-web
              </v-icon>
              Marketing
            </v-btn>
          </v-list-item>
        </v-list>
      </v-container>
    </v-navigation-drawer>
    <v-container fluid>
      <router-view />
    </v-container>
  </v-main>
</template>

<script>

export default {
  data () {
    return {
      drawer: false
    }
  },
  computed: {
  },
  async created () {
  }
}
</script>
<style lang="scss" scoped>
  .theme--light.v-btn-active::before.v-btn-active:hover::before {
    cursor: text !important;
  }
</style>
